* {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

body {
  line-height: 26px;
}