/* Basic styling for the container */
.register-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .back-button {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  h2 {
    font-family: 'Arial', sans-serif;
    margin-bottom: 20px;
  }
  
  /* Style the form group */
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  input:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  /* Style the register button */
  .register-button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .register-button:hover {
    background-color: #45a049;
  }
  
  /* Media query for small screens */
  @media (max-width: 768px) {
    .register-container {
      max-width: 90%;
      padding: 15px;
    }
  
    input, .register-button {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .register-container {
      padding: 10px;
    }
  
    input, .register-button {
      font-size: 12px;
    }
  }
  