.enroll-report {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-left: 30%;
}

.enr-field {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    /* justify-content: space-evenly; */
}


.stud-image {
    height: 100px;
    width: 100px;
}

.enr-comp-logo {
    width: 100px;
}

.report-comp-logo {
    margin-bottom: 26px;
    margin-left: 11.5%;
    padding: 10px;
}

label {
    margin-right: 30px;
}

.back-home-ctn{
    display: flex;
    justify-content: end;
}
.back-home-btn {
   
    background-color: #1C2CF3;
    color: white;
    border-radius: 3px;
    padding: 5px 14px;
    border: none;
    outline: none;
    margin-top: 15px;
    margin-bottom: 9px;
    margin-right: 5%;
}
